const OPTIONS_DATA_TYPE = [
	{
		id: 'organic',
		text: 'Органика'
	},
	{
		id: 'ws',
		text: 'Частотка'
	}
]
const OPTIONS_PROCESSING_STATUS = [
	{
		id: 'sent',
		text: 'Отправленные'
	},
	{
		id: 'parsed',
		text: 'Обработанные'
	}
]
const OPTIONS_RECOLLECT = [
	{
		id: 'true',
		text: 'Пересбор'
	},
	{
		id: 'false',
		text: 'Не пересбор'
	}
]

export { OPTIONS_DATA_TYPE, OPTIONS_PROCESSING_STATUS, OPTIONS_RECOLLECT }
